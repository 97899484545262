import {
  Component,
  Input,
  OnDestroy,
  Inject,
  ViewEncapsulation, ChangeDetectorRef, AfterViewChecked, HostListener
} from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { DOCUMENT, NgIf, NgClass } from '@angular/common';
import {LoaderService} from '../../service/loader-service.service';
import {PaymentInfoService} from '../../service/payment-info.service';
import { Subscription } from 'rxjs';
import { ExtendedModule } from '@angular/flex-layout/extended';

@Component({
    selector: 'app-spinner',
    template: `
    <div class="preloader" [ngClass]="isPayNowClick===true? 'loaderForPayNow':'' " *ngIf="isSpinnerVisible">
      <div id="load" class="load">
      <div class="user_msg_outer" *ngIf="isPayNowClick"><span class="user_msg">Your payment is under process, please do not hit the refresh or back button. Have patience. Thank you.</span>
      </div></div>
    </div>`,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, NgClass, ExtendedModule]
})
export class SpinnerComponent implements OnDestroy, AfterViewChecked {
  public isSpinnerVisible = true;
  private subscriptionPayNow: Subscription;
  private hideMsg: Subscription;
  isPayNowClick: boolean = false;
  @Input()
  public backgroundColor = 'rgba(0, 115, 170, 0.69)';

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private loaderService: LoaderService,
    private paymentInfoService : PaymentInfoService,
    private cdRef: ChangeDetectorRef
  ) {
    this.subscriptionPayNow = this.paymentInfoService.payNowEventListener.subscribe((val: boolean) => {
      if (val) {
        this.isPayNowClick = true;
      }
    });
    this.hideMsg = this.paymentInfoService.hideMessageventListener.subscribe((val: boolean) => {
      if (val) {
         this.isPayNowClick = false;
      }
    });
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isSpinnerVisible = false;
        }
      },
      () => {
        this.isSpinnerVisible = false;
      }
    );
  }
  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
    this.subscriptionPayNow.unsubscribe();
    this.hideMsg.unsubscribe();
 }

  ngAfterViewChecked(): void {
    this.loaderService.isLoading.subscribe((res: boolean) => {
      this.isSpinnerVisible = res;
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    });
  }


  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if(event.code === 'Escape'){
      this.loaderService.isLoading.next(false);
      this.loaderService.clearRequests.next(true);
    }
  }
}
